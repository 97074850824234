import __vite__cjsImport0_jquery from "/node_modules/.vite/deps/jquery.js?v=e42b3d3b"; const $ = __vite__cjsImport0_jquery.__esModule ? __vite__cjsImport0_jquery.default : __vite__cjsImport0_jquery;

import "/resources/js/tabler/tabler.js";



window.onload = function() {
    if (window.navigator.standalone) {
        document.body.classList.add('webapp-padding');
    }
};

window.hidePreloader = function() {
    let loader = document.getElementById('page-loader');
    if (loader) {
        loader.style.animationDuration = '0.25s';
        loader.classList.add('animate__animated', 'animate__fadeOut');
        loader.addEventListener('animationend', function() {
            loader.style.display = 'none';
            let content = document.getElementById('app');
            if (content) {
                content.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
                content.style.opacity = '0';
                // content.style.transform = 'translateY(20px)';
                content.style.display = 'flex';
                setTimeout(() => {
                    content.style.opacity = '1';
                    // content.style.transform = 'translateY(0)';
                }, 25);
            }
        });
    }
}


window.getTablerStyle = function(name) {
    let styles = getComputedStyle(document.body);
    return styles.getPropertyValue(`--tblr-${name}`).trim();
}


window.getTablerStyles = function(names) {
    let styles = getComputedStyle(document.body);
    return names.map(() => {
        return styles.getPropertyValue(`--tblr-${name}`).trim();
    });
}


window.onload = function() {
    if (window.navigator.standalone) {
        document.body.classList.add('webapp-padding');
    }
};

